<template>
  <v-app>
    <v-app-bar app class="white px-5 px-md-15">
        <router-link href="https://transave.com.ng">
          <span class="logo-text"><span class="secondary--text">Tran</span>Save</span>
        </router-link>
        <v-spacer></v-spacer>
        <div class="hidden-sm-and-down">
            <v-btn class="mx-1 primary--text" href="https://transave.com.ng/pos" text>POS</v-btn>
            <v-btn class="mx-1 primary--text" href="https://transave.com.ng/loan" text>Loans</v-btn>
            <v-btn class="mx-1 primary--text" href="https://transave.com.ng/airtime" text>Airtime &amp; Bills</v-btn>
            <v-btn class="mx-1 primary--text" href="https://transave.com.ng/savings" text>Savings</v-btn>
            <v-btn class="mx-1 primary--text" href="https://transave.com.ng/cards" text>Cards</v-btn>
            <v-btn class="mx-1 primary--text" href="https://transave.com.ng/faq" text>FAQ</v-btn>
            <v-btn class="mx-1 primary--text" :to="$store.getters['auth/isAuthenticated'] ? 'Dashboard' : '/'" text>{{ $store.getters['auth/isAuthenticated'] ? 'Dashboard' : 'Login' }}</v-btn>
            <v-btn class="text--white mx-1" color="primary" :to="$store.getters['auth/isAuthenticated'] ? '/auth/logout' : '/register'" >{{ $store.getters['auth/isAuthenticated'] ? 'Logout' : 'Get Started' }}</v-btn>
        </div>
        <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer fixed class="hidden-md-and-up" v-model="drawer">
      <template>
        <v-card light class="mx-auto pa-5 pt-6 elevation-0" width="300">
            <router-link to="/" class="text-center">
              <span class="logo-text"><span class="secondary--text">Tran</span>Save</span>
            </router-link>
            <v-list nav class="mt-4">
                <v-list-item href="https://transave.com.ng/pos">
                <v-list-item-title>POS</v-list-item-title>
                </v-list-item>

                <v-list-item href="https://transave.com.ng/loan">
                <v-list-item-title>Loan</v-list-item-title>
                </v-list-item>

                <v-list-item href="https://transave.com.ng/airtime">
                <v-list-item-title>Airtime &amp; Bills</v-list-item-title>
                </v-list-item>

                <v-list-item href="https://transave.com.ng/savings">
                <v-list-item-title>Savings</v-list-item-title>
                </v-list-item>

                <v-list-item href="https://transave.com.ng/cards">
                <v-list-item-title>Cards</v-list-item-title>
                </v-list-item>

                <v-list-item href="https://transave.com.ng/faq">
                <v-list-item-title>FAQ</v-list-item-title>
                </v-list-item>

                <v-list-item :to="$store.getters['auth/isAuthenticated'] ? '/dashboard' : '/auth/login'">
                <v-list-item-title>{{ $store.getters['auth/isAuthenticated'] ? 'Dasboard' : 'Login' }}</v-list-item-title>
                </v-list-item>

                <v-list-item :to="$store.getters['auth/isAuthenticated'] ? '/auth/logout' : '/auth/register'">
                <v-list-item-title>{{ $store.getters['auth/isAuthenticated'] ? 'Logout' : 'Register' }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
      </template>
    </v-navigation-drawer>
      <v-container>
        <router-view></router-view>
      </v-container>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      drawer: null,
    }
  },
}
</script>
<style lang="scss" scoped>
.nav-button {
  background: #171b70;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px;
  border-radius: 5px;
  color: #fff !important;
  text-transform: capitalize;
}
.footer {
  p.social {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    color: #ffffff;
  }
  .label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #c1a13d;
  }
  .item {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 27px;
    color: #FFFFFF;
    text-decoration: none;
  }
}
@media only screen and (max-width: 600px) {
  .footer {
    height: auto !important;
  }
}
.trans-logo{
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #C1A13D;
  text-decoration: none;
}
.item2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 27px;
    color: #FFFFFF;
    text-decoration: none;
  }
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.1),0 4px 5px 0 rgba(0,0,0,.04),0 1px 10px 0 rgba(0,0,0,.02) !important;
}
.logo-text {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  line-height: 33px !important;
  text-decoration: none;
}
a {
  text-decoration: none;
}
</style>
